import {
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  Text,
  Tooltip,
  Strong,
  StyledOption,
  Selectbox,
} from '@scriptaddicts/yamm-ui-components';
import React, { FC, useEffect } from 'react';
import { formatShortMonthDayYear } from 'utils/dates';
import { plurify } from 'utils/plurify';
import { logDisplaySidebar } from 'utils/userJourneyLogging';
import { useWorkspaceContext } from 'data/context/WorkspaceContext';
import useWorkspaceLocation from 'utils/router/useWorkspaceLocation';
import { useLocation } from 'react-router-dom';
import { useConnect } from './connect';
import { RemoveValidation } from './RemoveValidation';
import {
  FormContainer,
  PlainText,
  SettingContainer,
  TitleContainer,
  Divider,
  ActionContainer,
  LargeCloseButton,
  SmallCloseButton,
  OptionText,
  ActivateUsersButtonContainer,
} from './styles';
import { Props } from './types';
import {
  CannotDowngradeAlert,
  CannotRemovePaidUsers,
  MaxUsersReachedAlert,
  OwnerCannotBeRemoved,
  UserAlreadyPaidInAnotherSpaceAlert,
} from './alerts';

export const ManageUser: FC<Props> = (props) => {
  const {
    upgrade,
    active,
    disabled,
    pending,
    isLoading,
    activeFormChanged,
    changeMembers,
    removeActiveMembers,
    reInvite,
    cancelInvite,
    activateMember,
    removeDisabledMembers,
    memberLimit,
    isMaxUsersReached,
    disablePlanUpdate,
    onUpgradeSelectedUsers,
  } = useConnect(props);
  const { onClose } = props;
  const { pathname } = useWorkspaceLocation();
  const { search } = useLocation();

  const { id } = useWorkspaceContext();

  const showUpgradeButton = upgrade.requireUpgrade && upgrade.count > 0;
  useEffect(() => {
    if (showUpgradeButton || active.features.change.type || !!pending.count) {
      logDisplaySidebar(pathname, search, id);
    }
  }, [showUpgradeButton, active.features.change.type, pending.count]);

  return (
    <>
      <RemoveValidation {...removeActiveMembers} />

      {!!active.count && !!active.user ? (
        <LargeCloseButton
          data-show-upgrade-button={showUpgradeButton}
          onClick={onClose}
        />
      ) : (
        <SmallCloseButton
          data-show-upgrade-button={showUpgradeButton}
          onClick={onClose}
        />
      )}

      {showUpgradeButton && (
        <ActivateUsersButtonContainer>
          <Button
            variant="primary"
            aria-label="Upgrade selected users"
            data-log-event="go_to_space_billing"
            data-log-event-data={JSON.stringify({
              displayName: `Upgrade ${upgrade.count} ${plurify(
                upgrade.count,
                'user',
                'users',
              )}`,
            })}
            disabled={isLoading}
            onClick={onUpgradeSelectedUsers}
          >
            <span role="img" aria-label="rocket">
              🚀
            </span>{' '}
            Upgrade {upgrade.count} {plurify(upgrade.count, 'user', 'users')}
          </Button>
        </ActivateUsersButtonContainer>
      )}

      {!!active.count && (
        <>
          {active.user ? (
            <TitleContainer>
              <PlainText size="extraLarge" width="bold">
                {active.user.name}
              </PlainText>
              <PlainText size="base" width="regular">
                {active.user.email}
              </PlainText>
            </TitleContainer>
          ) : (
            <TitleContainer>
              <PlainText size="extraLarge" width="bold">
                {active.count} {plurify(active.count, 'user', 'users')}{' '}
                selected:
              </PlainText>
            </TitleContainer>
          )}
          <SettingContainer>
            {active.features.change.type && isMaxUsersReached && (
              <MaxUsersReachedAlert />
            )}
            {active.features.change.type &&
              active.warnings.type === 'ALL_PAID_SELECTED' && (
                <CannotDowngradeAlert />
              )}
            {!!active.user?.trial && (
              <>
                {active.user.trial !== -1 ? (
                  <Text type="paragraph" size="base" width="regular">
                    This user has a{' '}
                    <Strong>
                      trial license until{' '}
                      {formatShortMonthDayYear(new Date(active.user.trial))}.
                    </Strong>
                  </Text>
                ) : (
                  <Text type="paragraph" size="base" width="regular">
                    This user has an <Strong>unlimited trial license.</Strong>
                  </Text>
                )}
              </>
            )}
            {Object.values(active.features.change).some((i) => i) && (
              <FormContainer>
                {active.warnings.type === 'NOT_SAME_TYPE' && (
                  <Text type="paragraph" size="base" width="regular">
                    To update users Type, select users with the same Type.
                  </Text>
                )}
                {active.warnings.type === 'ALREADY_PAID' && (
                  <UserAlreadyPaidInAnotherSpaceAlert
                    subjectEmails={active.warnings.subjectEmails}
                  />
                )}
                {active.features.change.type && (
                  <FormGroup
                    disabled={isLoading || active.disabled.change.type}
                  >
                    <FormLabel
                      htmlFor="member-type"
                      tooltip="Free users have a limited quota and YAMM basic
                          features. Paid users have a full quota and access to
                          advanced features based on your space’s plan."
                    >
                      Type
                    </FormLabel>
                    <FormControl>
                      <Selectbox
                        id="member-type"
                        onChange={changeMembers.type.onChange}
                        value={changeMembers.type.value}
                        variant="gray"
                        maxElement="auto"
                        minElement={5}
                        disabled={disablePlanUpdate}
                      >
                        {changeMembers.type.value === 'TRIAL' && (
                          <StyledOption value="TRIAL" label="Trial" />
                        )}
                        <StyledOption value="FREE" label="Free user">
                          <OptionText>
                            Up to <Strong>50</Strong> recipients / day
                            {'\n'}
                            <Strong>Basic</Strong> features
                          </OptionText>
                        </StyledOption>
                        {active.isPersonal ? (
                          <StyledOption value="PRO_PERSONAL" label="Paid user">
                            <OptionText>
                              Up to <Strong>400</Strong> recipients / day
                              {'\n'}
                              <Strong>Advanced</Strong> features
                            </OptionText>
                          </StyledOption>
                        ) : (
                          <StyledOption value="PRO" label="Paid user">
                            <OptionText>
                              Up to <Strong>1500</Strong> recipients / day
                              {'\n'}
                              <Strong>Advanced</Strong> features
                            </OptionText>
                          </StyledOption>
                        )}
                      </Selectbox>
                    </FormControl>
                  </FormGroup>
                )}
                {active.features.change.role && (
                  <FormGroup
                    disabled={isLoading || active.disabled.change.role}
                  >
                    <FormLabel
                      htmlFor="member-role"
                      tooltip="Admins can manage users. Ownership transfer is available from the space settings."
                    >
                      Role
                    </FormLabel>
                    <FormControl>
                      <Selectbox
                        id="member-role"
                        onChange={changeMembers.role.onChange}
                        value={changeMembers.role.value}
                        variant="gray"
                        maxElement="auto"
                        minElement={5}
                      >
                        <StyledOption value="MEMBER" label="User" />
                        <StyledOption value="ADMIN" label="Admin">
                          Can manage users and billing
                        </StyledOption>
                      </Selectbox>
                    </FormControl>
                  </FormGroup>
                )}
                <ActionContainer>
                  <Button
                    variant="primary"
                    aria-label="Save settings"
                    data-log-event="go_to_checkout_summary"
                    data-log-event-data={JSON.stringify({
                      displayName: 'Save',
                    })}
                    disabled={
                      isLoading ||
                      changeMembers.isLoading ||
                      !Object.entries(active.features.change).some(
                        ([key, value]) =>
                          value &&
                          !(active.disabled.change as Record<string, boolean>)[
                            key
                          ] &&
                          activeFormChanged[key],
                      )
                    }
                    loading={changeMembers.isLoading}
                    onClick={changeMembers.onSave}
                  >
                    Save
                  </Button>
                  <Button
                    variant="secondary"
                    aria-label="Cancel settings"
                    disabled={isLoading || changeMembers.isLoading}
                    onClick={changeMembers.onCancel}
                  >
                    Cancel
                  </Button>
                </ActionContainer>
              </FormContainer>
            )}
            {Object.values(active.features.change).some((i) => i) &&
              active.features.remove && <Divider />}
            {active.features.remove && (
              <>
                <Button
                  variant="danger"
                  aria-label="Remove from space"
                  disabled={isLoading || active.disabled.remove}
                  onClick={removeActiveMembers.onOpen}
                >
                  Remove from space
                </Button>
                {active.warnings.cannotRemovePaidUsers && (
                  <CannotRemovePaidUsers />
                )}
                {active.warnings.remove === 'CANNOT_REMOVE_OWNER' && (
                  <OwnerCannotBeRemoved />
                )}
              </>
            )}
          </SettingContainer>
        </>
      )}
      {!!disabled.count && (
        <>
          <TitleContainer>
            <PlainText type="span" size="extraLarge" width="bold">
              {disabled.count} disabled{' '}
              {plurify(disabled.count, 'user', 'users')} selected:
            </PlainText>
          </TitleContainer>
          <SettingContainer>
            {disabled.features.activate && (
              <>
                {disabled.warnings.activate ? (
                  <Tooltip
                    tip={
                      disabled.warnings.activate === 'FREE_USERS_MAX_REACHED'
                        ? `Your current plan does not allow more than ${memberLimit} free ${plurify(
                            memberLimit,
                            'user',
                          )}. Remove active users to re-activate disabled users.`
                        : 'Your current plan does not allow users from a different domain. Upgrade your space to re-activate them.'
                    }
                  >
                    <ActionContainer>
                      <Button
                        variant="primary"
                        aria-label="Activate"
                        disabled={
                          isLoading ||
                          disabled.disabled.activate ||
                          activateMember.isLoading
                        }
                        loading={activateMember.isLoading}
                        onClick={activateMember.onClick}
                      >
                        Activate
                      </Button>
                    </ActionContainer>
                  </Tooltip>
                ) : (
                  <Button
                    variant="primary"
                    aria-label="Activate"
                    disabled={
                      isLoading ||
                      disabled.disabled.activate ||
                      activateMember.isLoading
                    }
                    loading={activateMember.isLoading}
                    onClick={activateMember.onClick}
                  >
                    Activate
                  </Button>
                )}
              </>
            )}
            {disabled.features.remove && (
              <Button
                variant="danger"
                aria-label="Remove from space"
                disabled={isLoading}
                onClick={removeDisabledMembers.onOpen}
              >
                Remove from space
              </Button>
            )}
            <RemoveValidation {...removeDisabledMembers} />
          </SettingContainer>
        </>
      )}
      {!!pending.count && (
        <>
          <TitleContainer>
            <PlainText type="span" size="extraLarge" width="bold">
              {pending.count} pending {plurify(pending.count, 'user', 'users')}{' '}
              selected:
            </PlainText>
          </TitleContainer>
          <SettingContainer>
            {pending.features.resend && (
              <Button
                icon="users"
                variant="primary"
                data-log-event="resend_invitation"
                data-log-event-data={JSON.stringify({
                  displayName: `Resend ${plurify(
                    pending.count,
                    'invite',
                    'invites',
                  )}`,
                })}
                aria-label={`Resend ${plurify(
                  pending.count,
                  'invite',
                  'invites',
                )}`}
                loading={reInvite.isLoading}
                disabled={isLoading || reInvite.isLoading}
                onClick={reInvite.onClick}
              >
                Resend {plurify(pending.count, 'invite', 'invites')}
              </Button>
            )}
            {pending.features.cancel && (
              <Button
                variant="danger"
                data-log-event="cancel_invitation"
                data-log-event-data={JSON.stringify({
                  displayName: `Cancel ${plurify(
                    pending.count,
                    'invitation',
                    'invitations',
                  )}`,
                })}
                aria-label={`Cancel ${plurify(
                  pending.count,
                  'invitation',
                  'invitations',
                )}`}
                loading={cancelInvite.isLoading}
                disabled={isLoading || cancelInvite.isLoading}
                onClick={cancelInvite.onClick}
              >
                Cancel {plurify(pending.count, 'invitation', 'invitations')}
              </Button>
            )}
          </SettingContainer>
        </>
      )}
    </>
  );
};
