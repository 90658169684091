export const ClarityUtils = {
  setup: () => {
    try {
      ((
        window: Window & typeof globalThis,
        document: Document,
        trackingCode: string,
      ) => {
        const w = window as any;
        w.clarity =
          w.clarity ||
          ((...args: any[]) => {
            (w.clarity.q = w.clarity.q || []).push(args);
          });
        const t: HTMLScriptElement = document.createElement('script');
        t.async = true;
        t.src = `https://www.clarity.ms/tag/${trackingCode}`;
        const y: HTMLScriptElement = document.getElementsByTagName('script')[0];
        y.parentNode?.insertBefore(t, y);
      })(window, document, `${process.env.REACT_APP_CLARITY_TRACKING_CODE}`);
    } catch {
      // continue regardless of error
    }
  },
  identify: (customId: string | null, customSessionId: string | undefined) => {
    try {
      if (typeof (window as any).clarity === 'function') {
        (window as any).clarity('identify', customId, customSessionId);
      }
    } catch {
      // continue regardless of error
    }
  },
};
